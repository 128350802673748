<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <ul class="partner-list">
                    <li
                        v-for="(item, index) in partners"
                        v-bind:key="'partners-' + index"
                    >
                        <div class="box">
                            <div class="title">{{ item.name }}</div>
                            <router-link
                                :to="{
                                    name: 'Partner',
                                    params: { id: item.id }
                                }"
                                class="stretched-link"
                            ></router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "../core/services/api.service";

export default {
    name: "Category",
    data() {
        return {
            partners: []
        };
    },
    components: {},
    beforeMount() {
        this.getPartners();
    },
    watch: {
        $route: function() {
            this.getPartners();
        }
    },
    methods: {
        getPartners() {
            ApiService.get(
                `/category/${this.$route.params.id}/partners`
            ).then(response => {
                this.partners = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
